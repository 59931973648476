import './Important.css';
import expert from '../../../img/important/expert.png';
import quality from '../../../img/important/quality-control.png';
import warning from '../../../img/important/profile.png';

function Important() {
  return (
    <div className="main__important">
      <h2 className="main-important-h2">
        <span>Why us!!</span>
      </h2>

      <div className="main-important-blocks">
        <div className="main-important-block">
          <img src={expert} alt="expert" className="expert" />
          <h3>Professionalism</h3>
          <p>
            "Dobra Atmosfera" offers flexible solutions tailored to customer
            needs. You can choose a convenient schedule and form of cleaning
            that will meet your expectations.
          </p>
        </div>
        <div className="main-important-block">
          <img src={quality} alt="expert" className="expert" />
          <h3>Quality</h3>
          <p>
            "Dobra Atmosfera" employs qualified specialists who use modern
            cleaning methods and professional equipment.
          </p>
        </div>
        <div className="main-important-block">
          <img src={warning} alt="expert" className="expert" />
          <h3>Individual approach</h3>
          <p>
            "Dobra Atmosfera" guarantees the highest quality of services thanks
            to a professional approach, modern equipment and attention to every
            detail.
          </p>
        </div>
      </div>
    </div>
  );
}
export default Important;
