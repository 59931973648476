import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Articles.css';
import quilon from '../../../img/articles/article3.jpg';
import quilon2 from '../../../img/articles/article3.3.jpg';
import { Cataloge } from './1_Article';
import WhatsApp from '../../../WhatsUp/WhatsappLink';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>EN</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang isLang={isLang}>
            {isLang && <Link to="/article2">PL</Link>}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

function Quilon() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>Carpet and rug cleaning Legnica</h2>

            <p>
              Washing carpets and rugs is an important activity that affects the
              cleanliness and hygiene of our homes. Despite regular vacuuming,
              various types of contaminants may accumulate on these surfaces,
              which affect not only aesthetics, but also our health. In this
              article, we will present several reasons why washing carpets and
              rugs is so important.
            </p>
            <p>
              First of all, washing carpets and rugs helps remove contaminants
              such as bacteria, viruses, mites, dust and pollen. These factors
              can affect our health, especially for people with allergies and
              asthma. Regularly washing carpets and rugs can help reduce the
              occurrence of allergic reactions and prevent the spread of
              infectious diseases.
            </p>
            <img className="quilon" src={quilon} title="quilon" alt="quilon" />
            <p>
              Secondly, carpets and rugs are exposed to various types of stains
              and dirt, such as oil, coffee or wine. Over time, these stains
              become more difficult to remove. Regular washing can help remove
              these stains and keep your carpet or rug in good condition for a
              longer period of time.
            </p>
            <p>
              Third, washing your rugs and rugs regularly can help maintain
              their appearance and durability. Over time, contaminants may
              accumulate on these surfaces, which may affect their structure and
              durability. Washing removes these contaminants and improves the
              surface quality, which positively affects the appearance and
              durability of carpets and rugs.
            </p>
            <img
              className="quilon2"
              src={quilon2}
              title="quilon"
              alt="quilon"
            />
            <p>
              Fourthly, washing carpets and rugs is also a matter of odors. Over
              time, odors from the environment may accumulate on these surfaces,
              especially in places where we smoke, cook or keep pets. Regular
              washing removes these odors and refreshes surfaces.
            </p>
            <p>
              To sum up, washing carpets and rugs is an important activity that
              affects the cleanliness and hygiene of our homes. Regular washing
              removes dirt, stains and odors and keeps carpets and rugs in good
              condition for a longer period of time. Therefore, it is worth
              washing regularly to ensure a clean and healthy environment in our
              homes.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}

function Article3Ru() {
  return (
    <div className="body_wrapper">
      <Header />
      <ButtomsLang />
      <Quilon />
      <WhatsApp />
      <Footer />
    </div>
  );
}
export default Article3Ru;
