import './Important.css';
import expert from '../../../img/important/expert.png';
import quality from '../../../img/important/quality-control.png';
import warning from '../../../img/important/profile.png';

function Important() {
  return (
    <div className="main__important">
      <h2 className="main-important-h2">
        <span>Dlaczego właśnie my!</span>
      </h2>

      <div className="main-important-blocks">
        <div className="main-important-block">
          <img src={warning} alt="expert" className="expert" />
          <h3>Indywidualne podejście</h3>
          <p>
            "Dobra Atmosfera" oferuje elastyczne rozwiązania dostosowane do
            potrzeb klienta. Możesz wybrać dogodny harmonogram i formę
            sprzątania, które spełnią Twoje oczekiwania.
          </p>
        </div>
        <div className="main-important-block">
          <img src={expert} alt="expert" className="expert" />
          <h3>Profesjonalizm</h3>
          <p>
            W "Dobra Atmosfera" pracują wykwalifikowani specjaliści, którzy
            stosują nowoczesne metody sprzątania oraz profesjonalny sprzęt.
          </p>
        </div>
        <div className="main-important-block">
          <img src={quality} alt="expert" className="expert" />
          <h3>Jakość</h3>
          <p>
            "Dobra Atmosfera" gwarantuje najwyższą jakość usług dzięki
            profesjonalnemu podejściu, nowoczesnemu sprzętowi i dbałości o każdy
            szczegół.
          </p>
        </div>
      </div>
    </div>
  );
}
export default Important;
