import './Services.css';
import main1 from '../../../img/Services/clean_sofa.jpg';
import main4 from '../../../img/Services/clean-matrass.png';
import main3 from '../../../img/Services/clean-pillow.png';
import main2 from '../../../img/Services/clean_chair.jpg';
import main5 from '../../../img/Services/window5.jpg';
import { Link } from 'react-router-dom';

function Services() {
  return (
    <>
      <div className="main__first-services">
        <div className=" main-services">
          <h3 className="h3">PROFESJONALNE PRANIE</h3>
          <h1 className="h1">
            KANAPY, FOTELE, MATERACE, WÓZKI, ZASŁONY, ROLETY, WYKŁADZINY,
            DYWANY, TAPICERKA SAMOCHODOWA...
          </h1>
          <h2 className="h2">
            👉 GWARANTUJEMY ZWROT 100% OPŁATY, JEŚLI KLIENT NIE BĘDZIE
            ZADOWOLONY Z EFEKTU PRANIA !!!
          </h2>
        </div>
        {/* <div className=" main-services">
          <h3 className="h3">PROFESJONALNE PRANIE</h3>
          <h1 className="h1">
            Mebli tapicerowanych, krzeseł, foteli, kanap, materacy, dywanów i
            wykładzin, tapicerki samochodowej przy użyciu najlepszych
            odpowiednich środków i sprzętu
          </h1>
          <h2 className="h2">
            👉 Naszy usługi dla domu, dla biur, lokali usługowych, restauracji,
            hoteli, placówek użyteczności publicznej, szkół, przedszkoli,
            żłobków!!!
          </h2>
        </div> */}

        <div className="main__services">
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main1} alt="photo1" />
            </div>
            <h2>Pranie kanap</h2>
            <p className="main-services-price">od 150 zł</p>
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main2} alt="photo1" />
            </div>
            <h2>Pranie foteli</h2>
            <p className="main-services-price">od 50 zł</p>
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main3} alt="photo1" />
            </div>
            <h2>Pranie poduszek</h2>
            <p className="main-services-price">od 10 zł</p>
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main4} alt="photo1" />
            </div>
            <h2>PRANIE MATERACY</h2>
            <p className="main-services-price">od 100 zł</p>
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main5} alt="photo6" />
            </div>
            <h2>mycie okien</h2>
            <p className="main-services-price">od 30 zł</p>
          </div>
        </div>
        <div className="main__link-details">
          <Link to="/price">Szczegółowo...</Link>
        </div>
      </div>
    </>
  );
}
export default Services;
