import SwiperArticles from '../../../UI/Swiper/Swiper';

function Articles() {
  return (
    <>
      <div className="main__second-services main-second">
        <div className="main-title">
          <p>Recommended articles</p>
          <h2>
            Everything about dry cleaning of furniture, carpets, car
            interiors...
          </h2>
        </div>
        <SwiperArticles />
      </div>
    </>
  );
}
export default Articles;
