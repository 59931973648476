import { Link } from 'react-router-dom';
import img_facebook from '../../../img/links/facebook2.png';
import img_instagram from '../../../img/links/instagram.png';
import img_youtube from '../../../img/links/youtube.png';

import './LinkSocial.css';

function LinkSocial() {
  return (
    <div className="header__list-social-media">
      <div className="header__social-link-item">
        <Link
          to="https://youtube.com/@dobraatmosfera?si=88lzciVN1jEHWIcJ"
          title="youtube"
          alt="youtube"
          target="_blank"
          className="header__instargam"
        >
          <img src={img_youtube} alt="instagram" />
        </Link>
      </div>
      <div className="header__social-link-item">
        <Link
          to="https://www.instagram.com/dobra_atmosfera.legnica?igsh=a3pvYTd6YTduZjJv"
          title="instagram"
          alt="instagram"
          target="_blank"
          className="header__instargam"
        >
          <img src={img_instagram} alt="instagram" />
        </Link>
      </div>
      <div className="header__social-link-item">
        <Link
          to="https://www.facebook.com/profile.php?id=61568975542484"
          target="_blank"
          alt="facebook"
          title="facebook"
          className="header_facebook"
        >
          <img src={img_facebook} alt="facebook" />
        </Link>
      </div>
    </div>
  );
}
export default LinkSocial;
