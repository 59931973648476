import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Price.css';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import React, { useState } from 'react';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import Lang from '../../../UI/Lang/Lang';
import WhatsApp from '../../../WhatsUp/WhatsappLink';
import chair from '../../../img/price/chair.png';
import armchair from '../../../img/price/armchair.png';
import sofa from '../../../img/price/sofa.png';
import pufa from '../../../img/price/pufa.png';
import matrass from '../../../img/price/matrass.png';
import softtoy from '../../../img/price/soft-toy.png';
import pram from '../../../img/price/pram.png';
import babysit from '../../../img/price/baby-car-sit.png';
import coner from '../../../img/price/sofa-coner.png';
import rug from '../../../img/price/rug.png';
import carpet from '../../../img/price/carpet.png';
import washcar1 from '../../../img/price/car-wash.png';
import washcar2 from '../../../img/price/car-wash-middle.png';
import washcar3 from '../../../img/price/car-wash-full.png';
import window from '../../../img/price/window.png';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  const closeLang = () => {
    setLang(false);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>PL</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang closeLang={closeLang} isLang={isLang}>
            {isLang ? (
              <Link to="/en/price">EN</Link>
            ) : (
              <Link to="/about">RU</Link>
            )}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}
function PriceRows() {
  return (
    <>
      <div className="price">
        <div className="price-title">
          <h1>Cennik usług:</h1>
        </div>

        <div className="price-padding">
          <div className="price-container">
            <div className="price-padding__items">
              <div className="price-item">
                <img src={sofa} alt="sofa" />
                <p className="price-name">Prania wersalka</p>
                <button className="price-cost" type="button">
                  od 80 do 250 zl
                </button>
              </div>
              <div className="price-item">
                <img src={chair} alt="chair" />
                <p className="price-name">Pranie krzesło</p>
                <button className="price-cost" type="button">
                  od 10 do 30 zl
                </button>
              </div>
              <div className="price-item">
                <img src={pufa} alt="pufa" />
                <p className="price-name">Pranie pufy</p>
                <button className="price-cost" type="button">
                  od 20 do 50 zl
                </button>
              </div>
              <div className="price-item">
                <img src={armchair} alt="armchair" />
                <p className="price-name">Pranie fotela</p>
                <button className="price-cost" type="button">
                  od 20 do 80 zl
                </button>
              </div>
              <div className="price-item">
                <img src={coner} alt="armchair" />
                <p className="price-name">Pranie narożnika</p>
                <button className="price-cost" type="button">
                  od 100 do 400 zl
                </button>
              </div>

              <div className="price-item">
                <img src={matrass} alt="matrass" />
                <p className="price-name">Prania materaca</p>
                <button className="price-cost" type="button">
                  od 100 do 400 zl
                </button>
              </div>
              <div className="price-item">
                <img src={softtoy} alt="softtoy" />
                <p className="price-name">Pranie pluszaków</p>
                <button className="price-cost" type="button">
                  od 10 do 200 zl
                </button>
              </div>
              <div className="price-item">
                <img src={pram} alt="pram" />
                <p className="price-name size">
                  Pranie gondoli wózków dziecięcych
                </p>
                <button className="price-cost" type="button">
                  od 50 do 200 zl
                </button>
              </div>
              <div className="price-item">
                <img src={babysit} alt="pram" />
                <p className="price-name size">
                  Pranie fotelików samochodowych dla dzieci
                </p>
                <button className="price-cost" type="button">
                  od 50 do 200 zl
                </button>
              </div>
              <div className="price-item">
                <img src={carpet} alt="pram" />
                <p className="price-name">Pranie dywanów</p>
                <button className="price-cost" type="button">
                  od 10 do 50 zł/m2
                </button>
              </div>
              <div className="price-item">
                <img src={window} alt="wash-window" />
                <p className="price-name size">Mycie okien</p>
                <button className="price-cost wider" type="button">
                  od 30 zł
                </button>
              </div>
              <div className="price-item">
                <img src={rug} alt="pram" />
                <p className="price-name">Pranie wykładziny</p>
                <button className="price-cost" type="button">
                  od 2 do 30 zł/m2
                </button>
              </div>
              <div className="price-item">
                <img src={washcar1} alt="pram" />
                <p className="price-name size">
                  Pranie podstawowe tapicerki (przednie fotele)
                </p>
                <button className="price-cost" type="button">
                  od 50 do 100 zł
                </button>
              </div>
              <div className="price-item">
                <img src={washcar2} alt="pram" />
                <p className="price-name size">
                  Pranie zaawansowane (fotele, kanapa)
                </p>
                <button className="price-cost" type="button">
                  od 150 do 200 zł
                </button>
              </div>
              <div className="price-item">
                <img src={washcar3} alt="pram" />
                <p className="price-name size">
                  Pranie expert (fotele, kanapa, boczki, wykładzina, podsufitka,
                  bagażnik)
                </p>
                <button className="price-cost" type="button">
                  od 400 do 1000 zł
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="outsidewidthtable">
          <p className="lastquestion">
            <span>
              {' '}
              Ceny podane poniżej mają charakter orientacyjny. Cena uzależniona
              jest od stopnia zabrudzenia oraz ilości zamówienia. Ostateczną
              cenę można poznać dzwoniąc do nas lub wysyłając zdjęcie tego co
              wymaga czyszczenia, a nasz pracownik poda dokładną wycenę.
            </span>
          </p>
          <p className="lastquestion">
            {' '}
            <span>Zapraszamy do skorzystania z naszych usług!</span>
          </p>
        </div>
      </div>
    </>
  );
}

function Price() {
  return (
    <div className="body_wrapper">
      <Header />
      <ButtomsLang />
      <PriceRows />
      <WhatsApp />
      <Footer />
    </div>
  );
}
export default Price;
