import './Services.css';
import main1 from '../../../img/Services/clean_sofa.jpg';
import main4 from '../../../img/Services/clean-matrass.png';
import main3 from '../../../img/Services/clean-pillow.png';
import main2 from '../../../img/Services/clean_chair.jpg';
import main5 from '../../../img/Services/window5.jpg';
import { Link } from 'react-router-dom';

function Services() {
  return (
    <>
      <div className="main__first-services">
        <div className=" main-services">
          <h3 className="h3">PROFESSIONAL WASHING</h3>
          <h1 className="h1">
            Upholstered furniture, chairs, armchairs, sofas, mattresses, carpets
            and rugs, car upholstery using the best appropriate means and
            equipment
          </h1>
          <h2 className="h2">
            👉 Our services for home, offices, commercial premises, restaurants,
            hotels, public facilities, schools, kindergartens, nurseries!!!
          </h2>
        </div>

        <div className="main__services">
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main1} alt="photo1" />
            </div>
            <h2>Washing couches</h2>
            <p className="main-services-price">from 150 pln</p>
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main2} alt="photo1" />
            </div>
            <h2>Washing armchairs</h2>
            <p className="main-services-price">from 50 pln</p>
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main3} alt="photo1" />
            </div>
            <h2>Washing pillows</h2>
            <p className="main-services-price">from 10 pln</p>
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main4} alt="photo1" />
            </div>
            <h2>WASHING MATTRESS</h2>
            <p className="main-services-price">from 100 pln</p>
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main5} alt="photo6" />
            </div>
            <h2>window washing</h2>
            <p className="main-services-price">from 30 pln</p>
          </div>
        </div>
        <div className="main__link-details">
          <Link to="/ru/price">More details...</Link>
        </div>
      </div>
    </>
  );
}
export default Services;
