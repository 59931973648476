import { Link } from 'react-router-dom';
import logo from '../../../img/logo.jpg';
import './Logo.css';
function Logo() {
  return (
    <>
      <Link to="/en/main" className="logo">
        <img className="logo__img" src={logo} alt="logo" title="SuperClean" />
      </Link>
    </>
  );
}
export default Logo;
